import { flexCenter } from '@/layout/styles/classes';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { PlainBtn } from './Buttons/PlainBtn';
import CLink from './CLink';

const StyledPagination = styled.div`
    ${flexCenter};
    margin-top: 96px;
`;

const Btn = styled(PlainBtn)<{ disable: string }>`
    ${flexCenter};
    width: 30px;
    margin-right: 8px;
    height: 30px;
    border: 1px solid rgba(35, 35, 36, 0.5);
    border-radius: 50%;
    transition: border-color 0.3s ease-in-out;
    font-size: 1.3rem;
    line-height: 1.3rem;
    font-weight: 500;

    :nth-last-of-type(2) {
        margin-left: 8px;
    }

    :last-of-type {
        margin-left: 0;
        margin-right: 0;
    }

    :hover,
    :focus {
        border-color: ${({ theme }) => theme.colors.purple1};
    }

    ${({ disable }) =>
        disable === 'true' &&
        css`
            display: none;
        `}

    @media (min-width: 600px) {
        margin-right: 16px;

        :nth-last-of-type(2) {
            margin-left: 16px;
        }
    }
`;

const Num = styled(CLink)`
    padding: 3px 9px;
    transition: background-color 0.3s ease-in-out;
    margin: 0 4px;
    border-radius: 4px;
    font-weight: 600;

    :hover,
    :focus {
        background-color: ${({ theme }) => theme.colors.purple3};
    }

    &.blog-pagination-active {
        background-color: ${({ theme }) => theme.colors.purple1};
        color: ${({ theme }) => theme.colors.white};
    }

    @media (min-width: 600px) {
        margin: 0 8px;
        padding: 4px 12px;
    }
`;

type PaginationProps = {
    currentPage: number;
    numPages: number;
};

const getStart = (currentPage: number, numPages: number) => {
    if (currentPage <= 3) return 1;
    if (currentPage + 2 >= numPages) return numPages - 4;
    else return currentPage - 2;
};

export const Pagination = ({ currentPage, numPages }: PaginationProps) => {
    if (!numPages || !currentPage) return null;

    const isFirst = currentPage === 1;
    const isLast = currentPage === numPages;

    const first = '/about/blog/';
    const last = `/about/blog/page/${numPages}/`;

    const prev = currentPage === 2 ? first : `/about/blog/page/${currentPage - 1}/`;
    const next = `/about/blog/page/${currentPage + 1}/`;

    const start = getStart(currentPage, numPages);
    const nums = Array.from({ length: 5 }).map((_, i) => i + start);

    return (
        <StyledPagination>
            <Btn
                as={CLink}
                to={first}
                rel="prev"
                disable={isFirst ? 'true' : 'false'}
                data-type="button"
            >
                &#171;
            </Btn>
            <Btn
                as={CLink}
                to={prev}
                rel="prev"
                disable={isFirst ? 'true' : 'false'}
                data-type="button"
            >
                &#60;
            </Btn>
            {nums.map(num => (
                <Num
                    to={num === 1 ? '/about/blog/' : `/about/blog/page/${num}/`}
                    key={num}
                    activeClassName="blog-pagination-active"
                    data-type="button"
                >
                    {num}
                </Num>
            ))}
            <Btn
                as={CLink}
                to={next}
                rel="next"
                disable={isLast ? 'true' : 'false'}
                data-type="button"
            >
                &#62;
            </Btn>
            <Btn
                as={CLink}
                to={last}
                rel="next"
                disable={isLast ? 'true' : 'false'}
                data-type="button"
            >
                &#187;
            </Btn>
        </StyledPagination>
    );
};
