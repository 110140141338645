import { Article } from '@/Article';
import { CommonHero } from '@/CommonHero';
import { IntroList } from '@/IntroList';
import { Seo } from '@/layout/Seo';
import { Pagination } from '@/Pagination';
import { css } from '@emotion/react';
import { graphql } from 'gatsby';
import { getImage, IGatsbyImageData, ImageDataLike, StaticImage } from 'gatsby-plugin-image';
import { Fragment } from 'react';

type BlogPageProps = {
    data: {
        allMdx: {
            nodes: {
                frontmatter: {
                    title: string;
                    date: string;
                    cover: { childImageSharp: ImageDataLike };
                };
                excerpt: string;
                fields: {
                    slug: string;
                };
            }[];
        };
    };
    pageContext: {
        currentPage: number;
        limit: number;
        numPages: number;
        skip: number;
    };
};

const BlogPage = ({ data, pageContext }: BlogPageProps) => {
    const { skip, limit, currentPage, numPages } = pageContext;

    const allBlogPosts = data.allMdx.nodes
        .sort(function (post1, post2) {
            return (
                new Date(post2.frontmatter.date).getTime() -
                new Date(post1.frontmatter.date).getTime()
            );
        })
        .slice(skip, skip + limit);

    return (
        <Fragment>
            <Seo
                title="Blog | Implant & General Dentistry | Auburn, WA"
                description="Check out the Nuttall Smiles blog for dental insights and news from the best dentist in Auburn and surrounding areas. Schedule an appointment today."
            />
            <CommonHero img>
                <StaticImage
                    src="../../assets/images/about/our-blog.jpg"
                    alt="read more"
                    layout="fullWidth"
                    loading="eager"
                />
            </CommonHero>
            <Article
                big
                css={css`
                    @media (min-width: 1366px) {
                        max-width: 1200px;
                    }
                `}
            >
                {allBlogPosts.map((post, i) => (
                    <IntroList
                        key={i}
                        title={post.frontmatter.title}
                        link={`/about/blog${post.fields.slug}/`}
                        description={<p>{post.excerpt}</p>}
                        image={getImage(post.frontmatter.cover.childImageSharp) as IGatsbyImageData}
                    />
                ))}
                <Pagination currentPage={currentPage} numPages={numPages} />
            </Article>
        </Fragment>
    );
};

export default BlogPage;

export const query = graphql`
    query BlogPage {
        allMdx(filter: { fileAbsolutePath: { regex: "/blog/" } }) {
            nodes {
                fields {
                    slug
                }
                excerpt(pruneLength: 400)
                frontmatter {
                    title
                    date
                    cover {
                        childImageSharp {
                            gatsbyImageData(
                                width: 253
                                height: 240
                                layout: FIXED
                                transformOptions: { cropFocus: CENTER }
                            )
                        }
                    }
                }
            }
        }
    }
`;
